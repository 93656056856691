a,
a:hover,
a:visited {
  color: #14b3d0;
}

p {
  font-family: brother-1816, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 30px;
}

.navbar {
  height: 80px;
  transition: all 0.25s ease;
}

.navbar-brand {
  margin-right: 0;
}

.navbar-brand img {
  width: 229px;
  max-width: 90%;
  height: auto;
}

.navbar-collapse:not(.show):not(.collapsing) .nav-item {
  height: 80px;
  text-align: center;
  padding-top: 28px;
  padding-left: 12px;
  padding-right: 12px;
}

.navbar-collapse:not(.show):not(.collapsing) .nav-item:last-child {
  width: 136px;
  background-image: url('../images/nav-right.svg');
  background-repeat: no-repeat;
}

.collapsing .navbar-nav,
.show .navbar-nav {
  background-color: rgba(0, 0, 0, 0.8)!important;
  position: fixed;
  top: 80px;
  width: 100%;
  left: 0;
  text-align: center;
}

.collapsing .navbar-nav .nav-item,
.show .navbar-nav .nav-item {
  padding: 16px 20px;
}

.nav-link {
  font-family: rift-soft, sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing:1.4px;
  padding: 0;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  display: inline-block;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #0ab0ee;
  border-bottom: 4px solid #0ab0ee;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}

#navbar-left {
  z-index: -1;
}

#navbar-left path {
  fill: #000000;
  fill-opacity: 0.39;
}

#navbar-left line {
  stroke: white;
  stroke-width: 2;
}

.btn-primary {
  background-color: #14b3d0;
  border-color: #14b3d0;
  font-family: rift-soft, sans-serif;
  font-weight: bold;
  font-size: 24px;
  padding: 0.75rem 0.75rem;
}

.carousel-indicators li {
  background-color: #90d1e9;
}

.carousel-indicators li.active {
  background-color: white;
}

.bg-dark {
  background-color: black!important;
}

.bg-dark-faded {
  background-color: rgba(0, 0, 0, 0.5)!important;
}

header {
  position: relative;
  background-color: black;
  height: 85vh;
  min-height: 25rem;
  max-height: 900px;
  width: 100%;
  overflow: hidden;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

footer {
  background-color: #292929;
  color: #60767e;
  font-family: brother-1816, sans-serif;
  font-weight: 100;
  font-size: 16px;
}

@media (pointer: coarse) and (hover: none) {
  header {
    background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
  }
  header video {
    display: none;
  }
}

h1 {
  font-family: rift-soft, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 48px;
  margin: 0 auto;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  letter-spacing: 7.2px;
}
@media (min-width: 992px) {
  h1 {
    font-size: 72px;
    max-width: 680px;
  }
}

h2 {
  font-family: rift-soft, sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 48px;
  letter-spacing: 4.8px;
  color: white;
  background-repeat: no-repeat;
  background-position: center center;
}
